import { useComputed, useSignal } from '@preact/signals';
import { useEffect, useMemo } from 'preact/hooks';
import { Pill } from 'components/atoms/Pill.tsx';
import {
	tabOffers,
	tabsMap,
	tabOffersLoading,
	tabActiveCategory,
	watchdogs,
	type TabId,
	type NearbyLocality,
	loadTabOffers,
	tabFilterFromUrl,
	nearbyDisplayedCategories,
	type CategoryOption,
	tabShowSavedOffers,
	getTabWatchdogs,
	moreOffersTabLink,
	tabDisableUseLayoutEffect,
} from 'components/homepage/signals';
import { OfferCardList } from 'components/offer/list/OfferList';
import { SignalsProvider } from 'context/SignalsContext';
import { type GetOffersResponse } from 'service/offer/resource';
import { localize } from 'i18n/localize';
import { user } from 'signals/login';
import { SessionStorage } from 'utils/SessionStorage.ts';
import { SearchType } from 'lib/dot/enum/searchType.ts';
import { TabOptions } from './TabOptions';
import { Tabs } from './Tabs';
import type { CategoryData } from 'service/category/model';
import pickBy from 'lodash/pickBy';
import { isWorthy } from 'utils/isWorthy';
import type { WatchdogCountData, WatchdogsSummary } from 'service/watchdog/model.ts';
import { getWatchdogsSummary } from 'service/watchdog/resource';
import { sendHomepageTabAnalytics, TabIdToTabAnalyticsIdMap } from 'lib/dot/homepage/utils.ts';
import { DOTHelper } from 'lib/dot/DOTHelper.ts';
import { TAB_OFFERS_PER_PAGE } from 'utils/constants.ts';

const $t = localize();

export type Props = {
	ssrOffers: GetOffersResponse;
	ssrActiveTab: TabId;
	ssrLocality?: NearbyLocality;
	ssrRadius?: number;
	ssrCategories: CategoryData[];
	ssrAllCategories: CategoryData[];
	ssrShowSavedOffers?: boolean;
	ssrCategory?: CategoryOption;
	ssrWatchdogs?: WatchdogCountData[];
	ssrWatchdogsSummary?: WatchdogsSummary;
	ssrMoreOffersTabLink: { href: string; text: string };
};

export function TabOffersIsland({
	ssrOffers,
	ssrActiveTab,
	ssrLocality,
	ssrRadius,
	ssrCategories,
	ssrAllCategories,
	ssrShowSavedOffers,
	ssrCategory,
	ssrWatchdogs,
	ssrWatchdogsSummary,
	ssrMoreOffersTabLink,
}: Props) {
	const isInitialized = useSignal(false);
	if (!isInitialized.value) {
		isInitialized.value = true;
		tabOffers.value = ssrOffers;
		tabActiveCategory.value = ssrCategory;
		nearbyDisplayedCategories.value = ssrCategories;
		watchdogs.value = { data: ssrWatchdogs ?? [], isLoading: false };
		tabShowSavedOffers.value = ssrShowSavedOffers ?? false;
		moreOffersTabLink.text.value = ssrMoreOffersTabLink?.text;
		moreOffersTabLink.href.value = ssrMoreOffersTabLink?.href;
		DOTHelper.addToStore({ tab: TabIdToTabAnalyticsIdMap[ssrActiveTab] });
	}

	const activeTab = useSignal(ssrActiveTab);
	const currentTab = activeTab.value ?? ssrActiveTab;
	const watchdogsSummary = useSignal<WatchdogsSummary | undefined>(ssrWatchdogsSummary);

	useEffect(() => {
		async function handleEvent(event: Event) {
			const { tab, locality, radius, category, showSavedOffers } = await tabFilterFromUrl(
				new URL(globalThis.window.location.href),
				ssrAllCategories,
			);

			if (event.type !== 'spa') {
				getWatchdogsSummary()
					.then((summary) => (watchdogsSummary.value = summary))
					.catch(() => (watchdogsSummary.value = undefined));
			}

			tabActiveCategory.value = category;
			tabShowSavedOffers.value = showSavedOffers;
			// Switching tabs has data reload wired into them. Use this hack to temporarily disable it when we want to
			// forcefully swap tabs and handle the reload ourselves from here.
			tabDisableUseLayoutEffect.value = true;
			activeTab.value = tab;
			setTimeout(() => {
				tabDisableUseLayoutEffect.value = false;
			}, 0);
			if (tab !== 'ulozene' || (tab === 'ulozene' && tabShowSavedOffers.peek())) {
				await loadTabOffers(
					tab,
					pickBy(
						{
							locality,
							radius,
							category,
							skipHistory: true,
						},
						isWorthy,
					),
				);
			} else if (tab === 'ulozene' && !tabShowSavedOffers.peek()) {
				await getTabWatchdogs({ skipHistory: true });
			}
		}

		globalThis.window.addEventListener('login', handleEvent);
		globalThis.window.addEventListener('logout', handleEvent);
		globalThis.window.addEventListener('forget', handleEvent);
		globalThis.window.addEventListener('spa', handleEvent);

		return () => {
			globalThis.window.removeEventListener('login', handleEvent);
			globalThis.window.removeEventListener('logout', handleEvent);
			globalThis.window.removeEventListener('forget', handleEvent);
			globalThis.window.removeEventListener('spa', handleEvent);
		};
	}, []);

	const handleTabChange = (tabId: TabId) => {
		if (activeTab.peek() !== tabId) {
			tabActiveCategory.value = undefined;
			activeTab.value = tabId;
			const analyticsTabId = TabIdToTabAnalyticsIdMap[tabId];
			sendHomepageTabAnalytics(analyticsTabId);
			DOTHelper.addToStore({ tab: analyticsTabId });
		}
	};

	const moreLink = useMemo(() => tabsMap.get(currentTab)?.moreLink, [currentTab]);
	const isBookmarked = currentTab === 'ulozene';
	const emptyState = isBookmarked
		? {
				title:
					user.value.state === 'login'
						? $t.offerList.emptyStateTitleBookmarked
						: $t.offerList.emptyStateTitleBookmarkedLogout,
				subtitle: $t.offerList.emptyStateSubtitleBookmarked,
				loginSourceComponent: 'favourites_add',
			}
		: {
				title: $t.offerList.emptyStateTitleOffers,
			};

	const noAds = currentTab === 'rozbalene' || isBookmarked;

	const offers = useComputed(() => ({ response: tabOffers.value, isLoading: tabOffersLoading.value }));

	return (
		<SignalsProvider value={{ offers, watchdogs, tabShowSavedOffers }}>
			<div class="max-w-screen-2xl m-auto">
				<div class="w-full max-w-[848px] m-auto text-left">
					<Tabs
						currentTab={currentTab}
						onTabChange={handleTabChange}
						watchdogsSummary={watchdogsSummary.value}
					/>
				</div>
				<hr class="border-neutral-15 w-full absolute left-0" />
			</div>

			<div class="max-w-screen-xl m-auto px-4">
				<TabOptions
					className="max-w-screen-2xl m-auto mb-4"
					currentTab={currentTab}
					ssrActiveTab={ssrActiveTab}
					ssrLocality={ssrLocality}
					ssrRadius={ssrRadius}
					ssrAllCategories={ssrAllCategories}
				/>

				{/* If we are showing saved watchdogs, hide the offers, however do not hide them if their length is 0,
				 because that also doubles as `pls login` message for unsigned users... */}
				{activeTab.value !== 'ulozene' ||
				(activeTab.value === 'ulozene' &&
					(tabShowSavedOffers.value ||
						(tabOffers.value.pagination?.total === 0 &&
							user.value.state !== 'login' &&
							tabShowSavedOffers.value))) ? (
					<OfferCardList
						eagerFirstImage={true}
						emptyStateLoginSourceComponent={emptyState.loginSourceComponent}
						emptyStateSubtitle={emptyState.subtitle}
						emptyStateTitle={emptyState.title}
						emptyStateWithSkeletons={false}
						noAds={noAds}
						showLogoutUserMessage={isBookmarked && ssrOffers?.results?.length === 0}
						skeletonCount={8}
					/>
				) : null}

				{!!tabOffers.value?.results?.length &&
					moreLink &&
					(activeTab.value !== 'ulozene' || tabOffers?.value?.pagination?.total > TAB_OFFERS_PER_PAGE) && (
						<div class="mt-8 text-center">
							<Pill
								className="px-12 py-3 text-base font-bold"
								pillType="secondary"
								as="a"
								href={moreLink.href}
								onClick={() => {
									SessionStorage.setSearchMeta({
										searchType: SearchType.MORE_OFFERS,
										tab: TabIdToTabAnalyticsIdMap[activeTab.peek()],
										usedGoods: activeTab.peek() === 'rozbalene',
									});
								}}
							>
								{moreLink.text}
							</Pill>
						</div>
					)}
			</div>
		</SignalsProvider>
	);
}
