import { Dropdown } from 'components/dropdown/Dropdown';
import { type DisplayCategory } from 'components/modal/categories/signals';
import { localize } from 'i18n/localize';
import noop from 'lodash/noop';
import { Icon } from 'components/atoms/Icon';
import { Pill } from 'components/atoms/Pill';
import { CategoryItem } from 'components/modal/categories/CategoryItem';
import type { CategoryData } from 'service/category/model';
import { useCallback } from 'preact/hooks';
import { cn } from 'utils/cn';
import { defaultCategory, type CategoryOption } from './signals';

const $t = localize();

type Props = {
	categories: DisplayCategory[] | CategoryData[];
	allowResponsive?: boolean;
	onCategorySelect?: (category: CategoryOption) => void;
	selectedCategoryId?: number;
	showCount?: boolean;
	placement?: Dropdown.Placement;
	className?: string;
	dataTest?: string;
};

export function CategoryDropdown({
	categories,
	onCategorySelect = noop,
	selectedCategoryId = 0,
	showCount = true,
	placement = 'bottom-right',
	className,
	dataTest,
}: Props) {
	const renderOpener = useCallback(
		({
			toggleOpen,
			openerRef,
			onKeyDown,
			aria,
		}: Dropdown.RenderOpenerProps<DisplayCategory[] | CategoryData[]>) => {
			const category = categories.find((category) => category.id === selectedCategoryId);

			return (
				<Pill
					pillType="secondary"
					onClick={toggleOpen}
					ref={openerRef}
					onKeyDown={onKeyDown}
					{...aria}
					className="flex justify-start items-center text-sm font-bold h-10"
				>
					{selectedCategoryId !== 0 ? category?.name : $t.categories.allCategories}
					&nbsp;
					<Icon type="keyboard_arrow_down" size={24} className="-m-2 ml-0" />
				</Pill>
			);
		},
		[categories, selectedCategoryId],
	);

	const renderOption = useCallback(
		({ option, index, props }: Dropdown.RenderOptionProps<DisplayCategory | CategoryData>) => (
			<CategoryItem
				key={index}
				category={option}
				onClick={(_, event) => {
					if (event && selectedCategoryId !== option.id) {
						props.onClick(event);
					}
					props.forceClose();
				}}
				className="!py-1"
				isActive={selectedCategoryId === option.id}
				showDropdownIcon={false}
				showCount={showCount}
			/>
		),
		[selectedCategoryId, showCount],
	);

	return (
		<div class="inline-flex items-center justify-center gap-2" data-test={dataTest}>
			<Dropdown
				renderOpener={renderOpener}
				placement={placement}
				className={cn(
					'mt-8 md:mb-8 md:!w-max md:!h-[360px] !p-4 grid grid-cols-1 md:grid-cols-2 gap-2',
					className,
				)}
				isMobileFullscreen
				mobileTitle={$t.categories.choose}
				onOptionSelected={(_: Event, category: DisplayCategory) => {
					onCategorySelect(category);
				}}
				options={categories}
				renderOption={renderOption}
			/>
			{selectedCategoryId ? (
				<Pill
					pillType="quaternary"
					className="px-2 pt-2 pb-0"
					onClick={() => onCategorySelect(defaultCategory)}
					title={$t.categories.clear}
				>
					<Icon type="close" size={24} className="!p-0 !m-0" />
				</Pill>
			) : null}
		</div>
	);
}
