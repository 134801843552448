import { cn } from 'utils/cn';
import {
	defaultCategory,
	getTabPopularOffers,
	type TabId,
	tabActiveCategory,
	tabOffers,
	tabDisableUseLayoutEffect,
} from './signals';
import { CategoryDropdown } from './CategoryDropdown';
import { useSignal } from '@preact/signals';
import type { CategoryData } from 'service/category/model';
import { useLayoutEffect } from 'preact/hooks';
import { BarDescription } from 'components/homepage/BarDescription.tsx';
import { localize } from 'i18n/localize.ts';
import { SearchType } from 'lib/dot/enum/searchType.ts';
import { HomepageTab } from 'lib/dot/enum/homepageTab.ts';
import { sendTabAnalytics } from 'components/homepage/utils.ts';

interface Props {
	ssrActiveTab?: TabId;
	ssrAllCategories: CategoryData[];
	className?: string;
}

const $t = localize();

export function PopularBar({ ssrActiveTab, ssrAllCategories, className }: Props) {
	const serverLoad = useSignal(ssrActiveTab === 'popularni');
	const categoryId = (tabActiveCategory.value || defaultCategory).id;

	useLayoutEffect(() => {
		if (!className?.includes('hidden') && !tabDisableUseLayoutEffect.peek()) {
			const { id, name, seoName } =
				ssrAllCategories.find((category) => category.id === categoryId) ?? defaultCategory;

			if (!serverLoad.peek()) getTabPopularOffers({ category: { id, name, seoName } });
			else serverLoad.value = false;
		}
	}, [className]);

	return (
		<div class={cn('flex flex-wrap justify-between items-center gap-4 smp:flex-nowrap', className)}>
			<BarDescription text={$t.tabsDescriptions.popular} />
			<CategoryDropdown
				dataTest="tab-category-filter"
				categories={ssrAllCategories}
				selectedCategoryId={categoryId}
				placement={'bottom-right'}
				showCount={false}
				onCategorySelect={async (category) => {
					if (category.id === categoryId) return;

					tabActiveCategory.value = category;

					await getTabPopularOffers({ category });
					sendTabAnalytics({
						category: category,
						tab: HomepageTab.POPULAR,
						usedGoods: false,
						itemCount: tabOffers.peek().pagination?.total || tabOffers.peek().results.length,
						searchType: SearchType.CATEGORY,
					});
				}}
			/>
		</div>
	);
}
