import { SavedOfferBar } from 'components/homepage/SavedOfferBar.tsx';
import { NearbyBar } from './NearbyBar';
import { PopularBar } from './PopularBar';
import { type NearbyLocality, type TabId } from './signals';
import type { CategoryData } from 'service/category/model';
import { ECommerceBar } from './ECommerceBar';

export function TabOptions({
	currentTab,
	ssrActiveTab,
	ssrLocality,
	ssrRadius,
	ssrAllCategories,
	className,
}: {
	currentTab: TabId;
	ssrActiveTab: TabId;
	ssrLocality: NearbyLocality | undefined;
	ssrRadius: number | undefined;
	ssrAllCategories: CategoryData[];
	className: string;
}) {
	return (
		<div class={className}>
			<SavedOfferBar ssrActiveTab={ssrActiveTab} className={currentTab !== 'ulozene' ? 'hidden' : 'mt-4'} />
			<NearbyBar
				ssrActiveTab={ssrActiveTab}
				ssrAllCategories={ssrAllCategories}
				ssrLocality={ssrLocality}
				ssrRadius={ssrRadius}
				className={currentTab !== 'v-okoli' ? 'hidden' : 'mt-4'}
			/>
			<PopularBar
				ssrActiveTab={ssrActiveTab}
				ssrAllCategories={ssrAllCategories}
				className={currentTab !== 'popularni' ? 'hidden' : 'mt-4'}
			/>
			<ECommerceBar
				ssrActiveTab={ssrActiveTab}
				ssrAllCategories={ssrAllCategories}
				className={currentTab !== 'rozbalene' ? 'hidden' : 'mt-4'}
			/>
		</div>
	);
}
