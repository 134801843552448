import { useSignal } from '@preact/signals';
import { useRef, useLayoutEffect } from 'preact/hooks';
import Link from 'components/atoms/Link';
import { FilterLocation } from 'components/filters/FilterLocation';
import { GET_GPS_LOCATION_ID } from 'components/filters/location/SuggestionsUtils';
import { localize } from 'i18n/localize';
import { getCurrentPositionLocality } from 'lib/geo/geo';
import { cn } from 'utils/cn';
import { LOCALITY_CELA_CR_AS_SUGGESTION } from 'utils/constants';
import { getLocalityName } from 'utils/getLocalityName';
import {
	defaultCategory,
	defaultLocality,
	getTabNearbyOffers,
	tabOffersLoading,
	nearbyDisplayedCategories,
	type NearbyLocality,
	type TabId,
	tabOffers,
	tabActiveCategory,
	tabDisableUseLayoutEffect,
} from './signals';
import { resolveLocality } from 'service/locality/resource';
import { toastMessages } from 'components/toastMessage/signals';
import { Dropdown } from 'components/dropdown/Dropdown';
import { useMouseEnterWithClick } from 'utils/mouseEvent';
import { CategoryDropdown } from './CategoryDropdown';
import type { CategoryData } from 'service/category/model';
import { getDefaultRadius } from 'utils/locality';
import { SearchType } from 'lib/dot/enum/searchType.ts';
import { sendTabAnalytics, sendTabLocationAnalytics, sendTabRadiusAnalytics } from 'components/homepage/utils.ts';
import { HomepageTab } from 'lib/dot/enum/homepageTab.ts';

interface Props {
	className?: string;
	ssrActiveTab?: TabId;
	ssrAllCategories: CategoryData[];
	ssrLocality?: NearbyLocality | undefined;
	ssrRadius?: number | undefined;
}

const $t = localize();
const nearby = $t.nearby;

export function NearbyBar({ className, ssrActiveTab, ssrAllCategories, ssrLocality, ssrRadius }: Props) {
	const serverLoad = useSignal(ssrActiveTab === 'v-okoli');
	const nearbyLocality = useSignal(ssrLocality ?? defaultLocality);
	const nearbyRadius = useSignal(ssrRadius ?? 5);
	const loadingGps = useSignal(false);
	const openLocalityInput = useSignal(false);
	const dropdownContainerRef = useRef<HTMLDivElement>(null); // Fix for dropdowns not working on phone
	const localityFilterRef = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		if (!className?.includes('hidden') && !tabDisableUseLayoutEffect.peek()) {
			if (nearbyLocality.value?.id === 0 && ssrLocality === undefined) {
				tabOffersLoading.value = true;
				getCurrentPositionLocality(loadingGps)
					.then((currentPosition) => {
						if (currentPosition) {
							const entityType = currentPosition.wardId
								? 'ward'
								: currentPosition.quarterId
									? 'quarter'
									: 'municipality';
							const entityId =
								currentPosition.wardId || currentPosition.quarterId || currentPosition.municipalityId;

							nearbyLocality.value = {
								...currentPosition,
								id: currentPosition.entityId,
								name: getLocalityName(currentPosition),
								entityId,
								entityType,
							};
							nearbyRadius.value = getDefaultRadius();
						}
					})
					.finally(() => {
						if (!serverLoad.peek())
							getTabNearbyOffers({
								locality: nearbyLocality.peek(),
								radius: nearbyRadius.peek(),
								category: tabActiveCategory.peek() || defaultCategory,
							});
						else serverLoad.value = false;
					});
			} else {
				if (!serverLoad.peek())
					getTabNearbyOffers({
						locality: nearbyLocality.peek(),
						radius: nearbyRadius.peek(),
						category: tabActiveCategory.peek() || defaultCategory,
					});
				else serverLoad.value = false;
			}
		}
	}, [className]);

	const filterLocation = (
		<FilterLocation
			forwardedRef={localityFilterRef}
			iconClass="fill-dark-blue"
			inputClass="input-gray-if-not-empty nowrap leading-[38px] py-0 box-border"
			suggestionContainerClass="absolute"
			wrapperClassname="z-50"
			locateMeText={nearby.localityLocateMe}
			radiusValue={nearbyRadius.value}
			localityValue={nearbyLocality.value || undefined}
			radiusDropdownContainerPortalRefElement={dropdownContainerRef}
			loadingGps={loadingGps.value}
			onLocationInput={async (
				_event,
				suggestion = LOCALITY_CELA_CR_AS_SUGGESTION,
				resolveGpsLocalityCallback,
			) => {
				if (suggestion.entityId === GET_GPS_LOCATION_ID) {
					const currentPosition = await getCurrentPositionLocality(loadingGps);
					if (currentPosition) {
						resolveGpsLocalityCallback(currentPosition);

						nearbyLocality.value = {
							...currentPosition,
							id: currentPosition.entityId,
							name: getLocalityName(currentPosition),
						};
						sendTabLocationAnalytics(currentPosition, true, HomepageTab.NEARBY);
						nearbyRadius.value = getDefaultRadius();
						await getTabNearbyOffers({
							locality: nearbyLocality.peek(),
							radius: nearbyRadius.peek(),
							category: tabActiveCategory.peek() || defaultCategory,
						});
					}
				} else {
					try {
						if (suggestion.entityId === 0) {
							sendTabLocationAnalytics(null, false, HomepageTab.NEARBY);
							nearbyLocality.value = defaultLocality;
						} else {
							const resolvedLocality = await resolveLocality({
								entity_id: suggestion.entityId,
								entity_type: suggestion.entityType,
							});
							nearbyLocality.value = {
								...resolvedLocality,
								id: suggestion.entityId,
								name: suggestion.suggestFirstRow || suggestion.seoName,
							};
							sendTabLocationAnalytics(resolvedLocality, false, HomepageTab.NEARBY);
						}
						await getTabNearbyOffers({
							locality: nearbyLocality.peek(),
							radius: nearbyRadius.peek(),
							category: tabActiveCategory.peek() || defaultCategory,
						});
					} catch {
						toastMessages.error('Nepodařilo se načíst lokalitu');
						return;
					}
				}
				sendTabAnalytics({
					locality: nearbyLocality.peek(),
					category: tabActiveCategory.peek() || defaultCategory,
					radius: nearbyRadius.peek(),
					tab: HomepageTab.NEARBY,
					usedGoods: false,
					itemCount: tabOffers.peek().pagination.total,
					searchType: SearchType.FILTER,
				});
			}}
			onRadiusInput={async (_event, radius) => {
				sendTabRadiusAnalytics(radius, HomepageTab.NEARBY);
				nearbyRadius.value = radius;
				await getTabNearbyOffers({
					locality: nearbyLocality.peek(),
					radius: nearbyRadius.peek(),
					category: tabActiveCategory.peek() || defaultCategory,
				});
				sendTabAnalytics({
					locality: nearbyLocality.peek(),
					category: tabActiveCategory.peek() || defaultCategory,
					radius: nearbyRadius.peek(),
					tab: HomepageTab.NEARBY,
					usedGoods: false,
					itemCount: tabOffers.peek().pagination.total,
					searchType: SearchType.FILTER,
				});
			}}
			useLocalState={true}
			useRadiusDropdown={true}
		/>
	);

	return (
		<div class={cn('flex flex-wrap justify-between items-center sm:flex-nowrap gap-2 px-0', className)}>
			<div class="leading-[38px] inline-flex flex-none items-start mt-0" data-test="tab-location-filter">
				<span class={'text-light-blue mr-1'}>Lokalita:</span>
				{openLocalityInput.value ? (
					filterLocation
				) : (
					<div class={cn('flex items-start', loadingGps.value && 'animate-pulse')}>
						{nearbyLocality.value?.id === 0
							? nearbyLocality.value?.name
							: `${nearbyLocality.value?.name}${nearbyRadius.value && nearbyRadius.value > 0 ? ` ${nearby.nearby} ${nearbyRadius.value} km` : ''}`}
						<span class="text-light-blue mx-1">-</span>
						<Link
							classNameOverride="mr-2 font-medium link inline-block cursor-pointer hidden sm:inline"
							onClick={() => {
								openLocalityInput.value = true;
								setTimeout(() => {
									localityFilterRef.current?.focus();
								}, 0);
							}}
						>
							{nearby.change}
						</Link>
						<div class="sm:hidden flex gap-3 flex-wrap justify-between">
							<Dropdown
								renderOpener={({ openerRef, toggleOpen, open }) => (
									<button
										ref={openerRef}
										{...useMouseEnterWithClick(open, () => {
											toggleOpen();
										})}
										class="mr-2 font-light link"
									>
										{nearby.change}
									</button>
								)}
								mobileTitle={$t.filters.mobileLabelFilters}
								isMobileFullscreen
							>
								<h2 class="text-2xl font-bold mb-4">{$t.filters.labelLocality}</h2>
								{filterLocation}
							</Dropdown>
						</div>
						<div ref={dropdownContainerRef}></div>
					</div>
				)}
			</div>
			<div>
				<CategoryDropdown
					dataTest={'tab-category-filter'}
					categories={nearbyDisplayedCategories.value ?? ssrAllCategories}
					selectedCategoryId={(tabActiveCategory.value || defaultCategory).id}
					placement={'bottom-right'}
					onCategorySelect={async (category) => {
						if (category.id === (tabActiveCategory.peek() || defaultCategory)?.id) return;

						tabActiveCategory.value = category;

						await getTabNearbyOffers({
							locality: nearbyLocality.peek(),
							radius: nearbyRadius.peek(),
							category: tabActiveCategory.peek() || defaultCategory,
						});
						sendTabAnalytics({
							locality: nearbyLocality.peek(),
							category: tabActiveCategory.peek() || defaultCategory,
							radius: nearbyRadius.peek(),
							tab: HomepageTab.NEARBY,
							usedGoods: false,
							itemCount: tabOffers.peek().pagination.total,
							searchType: SearchType.CATEGORY,
						});
					}}
				/>
			</div>
		</div>
	);
}
