import { signal, Signal } from '@preact/signals';
import { DEFAULT_OFFERS_VALUE } from 'signals/offers';
import { toastMessages } from 'components/toastMessage/signals';
import {
	getOffersSearch,
	getFavoriteOffers,
	type GetOffersResponse,
	type GetOffersParams,
	getPopularOffers,
} from 'service/offer/resource';
import { getWatchdogsCounts } from 'service/watchdog/resource';
import { WatchdogsSignal, DEFAULT_WATCHDOGS_VALUE } from 'signals/watchdog';
import {
	CATEGORY_ALL_CATEGORIES_SEO_NAME,
	LOCALITY_CELA_CR,
	LOCALITY_CELA_CR_AS_SUGGESTION,
	LOCALITY_TYPES,
	TAB_OFFERS_PER_PAGE,
} from 'utils/constants';
import { ApiError } from 'utils/generic';
import { localize } from 'i18n/localize';
import { searchUnpackedOffers } from 'service/unpacked/resource';
import { remoteFilterToUnpackedOffersFilter } from 'service/unpacked/utils';
import type { LocalityData } from 'service/locality/model';
import omitBy from 'lodash/omitBy';
import { CATEGORY_ALL_CATEGORIES, type CategoryData } from 'service/category/model';
import { searchCategories } from 'service/category/resource';
import { displayCategories } from 'signals/category';
import { generateLinkFromLocality } from 'utils/url';
import { baseUrl } from 'utils/env';
import { getLocalityName } from 'utils/getLocalityName';
import { resolveLocality } from 'service/locality/resource';
import { getDefaultRadius } from 'utils/locality';
import { navigateSPA } from 'lib/history/utils.ts';

const $t = localize();
let controller = new AbortController();

export const tabOffers = new Signal<GetOffersResponse>();
export const tabOffersLoading = new Signal<boolean>(false);
export const tabShowSavedOffers = new Signal<boolean>(false);
export const tabDisableUseLayoutEffect = new Signal<boolean>(false);
export const tabActiveCategory = new Signal<undefined | CategoryOption>(undefined);

const savedOffersLoading = new Signal<boolean>(false);
export const getTabBookmarkedOffers = (options?: GetTabOptions) => {
	if (tabOffersLoading.peek() && !watchdogs.peek().isLoading) {
		controller.abort('TabBookmarkedOffers');
		controller = new AbortController();
	} else {
		savedOffersLoading.value = true;
		tabOffersLoading.value = true;
	}

	if (globalThis?.window && !options?.skipHistory) {
		navigateSPA('/?zalozka=ulozene&showSavedOffers=true');
	}

	return getFavoriteOffers(
		{
			limit: String(TAB_OFFERS_PER_PAGE),
			sort: '-create_date',
		},
		options?.headers,
		controller,
	)
		.then((data) => {
			tabOffers.value = data;
			savedOffersLoading.value = false;
			tabOffersLoading.value = false;

			moreOffersTabLink.href.value = '/ulozene-nabidky';
			moreOffersTabLink.text.value = $t.offerList.moreBookmarkedOffers;

			return { offers: data };
		})
		.catch((error) => {
			if (error instanceof ApiError && error.cause.status !== 401) {
				toastMessages.addMessage('Nepodařilo se načíst uložené inzeráty', 'error');
			}

			if (error !== 'TabBookmarkedOffers') {
				tabOffers.value = DEFAULT_OFFERS_VALUE;
				savedOffersLoading.value = false;
				tabOffersLoading.value = false;
			}
		});
};

export const watchdogs = new WatchdogsSignal({ data: [], isLoading: false });

export const getTabWatchdogs = (options?: GetTabOptions) => {
	if (tabOffersLoading.peek() && !savedOffersLoading.peek()) {
		controller.abort('TabWatchdogs');
		controller = new AbortController();
	} else {
		watchdogs.value = { data: [], isLoading: true };
	}

	if (globalThis?.window && !options?.skipHistory) {
		globalThis.window.history.pushState(null, '', '/?zalozka=ulozene');
	}

	return getWatchdogsCounts(options?.headers, controller)
		.then((data) => {
			return (watchdogs.value = { data, isLoading: false });
		})
		.catch((error) => {
			if (error instanceof ApiError) {
				if (error.cause.status !== 401) {
					toastMessages.addMessage('Nepodařilo se načíst uložená hledání', 'error');
				}
			}

			if (error !== 'TabWatchdogs') {
				watchdogs.value = DEFAULT_WATCHDOGS_VALUE;
				tabOffers.value = DEFAULT_OFFERS_VALUE;
				tabOffersLoading.value = false;
			}
		});
};

export type NearbyLocality = LocalityData & { id: number; name: string };
export type CategoryOption = { id: number; name: string; seoName: string };

export const defaultLocality: NearbyLocality = {
	...(LOCALITY_CELA_CR as unknown as LocalityData),
	id: 0,
	entityId: LOCALITY_CELA_CR_AS_SUGGESTION.entityId,
	entityType: LOCALITY_CELA_CR_AS_SUGGESTION.entityType,
	name: $t.nearby.localityAllCzechia,
};

export const defaultCategory: CategoryOption = {
	id: 0,
	name: CATEGORY_ALL_CATEGORIES.name,
	seoName: CATEGORY_ALL_CATEGORIES_SEO_NAME,
};
export const nearbyDisplayedCategories = signal<CategoryData[]>();

export const getTabNearbyOffers = (options?: GetTabOptions) => {
	const locality = options?.locality ?? defaultLocality;
	const radius = options?.radius ?? getDefaultRadius();
	const category = options?.category ?? defaultCategory;

	if (tabOffersLoading.peek()) {
		controller.abort('TabNearbyOffers');
		controller = new AbortController();
	} else {
		tabOffersLoading.value = true;
	}

	if (globalThis?.window && !options?.skipHistory) {
		navigateSPA(
			`/?zalozka=v-okoli&kde=${locality?.entityType[0]}${locality?.entityId}&radius=${radius}&categoryId=${category?.id ?? 0}`,
		);
	}

	const filter = {
		locality: `${locality?.entityType},${locality?.entityId}`,
		radius_precision: String(radius),
		limit: String(TAB_OFFERS_PER_PAGE),
		category_id: category?.id || undefined,
	};

	return Promise.allSettled([
		getOffersSearch(omitBy(filter, (value) => !value) as GetOffersParams, options?.headers, controller),
		searchCategories(
			{ locality: filter.locality, limit: '12', radius_precision: String(radius) },
			options?.headers,
			controller,
		),
	]).then(([dataOffers, dataCategories]) => {
		moreOffersTabLink.href.value =
			locality && locality.entityId !== LOCALITY_CELA_CR_AS_SUGGESTION.entityId && category
				? generateLinkFromLocality(locality, radius, baseUrl() + '/' + category.seoName)
				: '/' + CATEGORY_ALL_CATEGORIES_SEO_NAME;
		moreOffersTabLink.text.value = $t.offerList.moreOffers;

		if (dataCategories.status === 'fulfilled') {
			nearbyDisplayedCategories.value = dataCategories.value;
		} else if (dataCategories.status === 'rejected' && dataCategories.reason !== 'TabNearbyOffers') {
			nearbyDisplayedCategories.value = [...displayCategories.peek()];
		}

		if (dataOffers.status === 'fulfilled') {
			tabOffers.value = dataOffers.value;
		} else if (dataOffers.status === 'rejected' && dataOffers.reason !== 'TabNearbyOffers') {
			tabOffers.value = DEFAULT_OFFERS_VALUE;
		}

		if (
			!(dataOffers.status === 'rejected' && dataOffers.reason === 'TabNearbyOffers') &&
			!(dataCategories.status === 'rejected' && dataCategories.reason === 'TabNearbyOffers')
		) {
			tabOffersLoading.value = false;
		}

		if (dataOffers.status === 'fulfilled' && dataCategories.status === 'rejected') {
			return { offers: dataOffers.value };
		} else if (dataOffers.status === 'fulfilled' && dataCategories.status === 'fulfilled') {
			return { offers: dataOffers.value, categories: dataCategories.value };
		}

		return undefined;
	});
};

export const getTabFromECommerce = (options?: GetTabOptions) => {
	if (tabOffersLoading.peek()) {
		controller.abort('TabFromECommerce');
		controller = new AbortController();
	} else {
		tabOffersLoading.value = true;
	}

	const category = options?.category ?? defaultCategory;

	if (globalThis?.window && !options?.skipHistory) {
		navigateSPA(`/?zalozka=rozbalene&categoryId=${category?.id ?? 0}`);
	}

	return searchUnpackedOffers(
		remoteFilterToUnpackedOffersFilter({
			limit: String(TAB_OFFERS_PER_PAGE),
			category_id: String(category.id),
		}),
		options?.headers,
		controller,
	)
		.then((data) => {
			tabOffers.value = data;
			tabOffersLoading.value = false;

			moreOffersTabLink.href.value = `/${category.seoName}?rozbalene=ano`;
			moreOffersTabLink.text.value = $t.offerList.moreOffers;

			return { offers: data };
		})
		.catch((error) => {
			if (error instanceof ApiError && error.cause.status !== 401) {
				toastMessages.addMessage('Nepodařilo se načíst inzeráty', 'error');
			}

			if (error !== 'TabFromECommerce') {
				tabOffers.value = DEFAULT_OFFERS_VALUE;
				tabOffersLoading.value = false;
			}
		});
};

export const getTabPopularOffers = (options?: GetTabOptions) => {
	if (tabOffersLoading.peek()) {
		controller.abort('TabPopularOffers');
		controller = new AbortController();
	} else {
		tabOffersLoading.value = true;
	}

	const category = options?.category ?? defaultCategory;
	const categoryId = category.id;

	if (globalThis?.window && !options?.skipHistory) {
		navigateSPA(`/?zalozka=popularni&categoryId=${categoryId}`);
	}

	return getPopularOffers(categoryId, options?.headers, controller)
		.then((data) => {
			tabOffers.value = data;
			tabOffersLoading.value = false;

			moreOffersTabLink.href.value = `/${category.seoName}`;
			if (categoryId === 0) {
				moreOffersTabLink.text.value = $t.homepage.goToAll;
			} else {
				moreOffersTabLink.text.value = $t.homepage.goToCategory + category.name;
			}

			return { offers: data };
		})
		.catch((error) => {
			if (error instanceof ApiError && error.cause.status !== 401) {
				toastMessages.addMessage('Nepodařilo se načíst inzeráty', 'error');
			}

			if (error !== 'TabPopularOffers') {
				tabOffers.value = DEFAULT_OFFERS_VALUE;
				tabOffersLoading.value = false;
			}
		});
};

export const moreOffersTabLink = {
	href: signal('/' + CATEGORY_ALL_CATEGORIES_SEO_NAME),
	text: signal($t.homepage.goToAll),
};

export const tabsMap = new Map([
	[
		'popularni',
		{
			label: $t.tabs.popular,
			icon: 'hotel_class',
			getData: getTabPopularOffers,
			moreLink: moreOffersTabLink,
		},
	],
	[
		'ulozene',
		{
			label: $t.tabs.bookmarked,
			icon: 'bookmarks',
			getData: getTabBookmarkedOffers,
			moreLink: moreOffersTabLink,
		},
	],
	[
		'v-okoli',
		{
			label: $t.tabs.nearby,
			icon: 'distance',
			getData: getTabNearbyOffers,
			moreLink: moreOffersTabLink,
		},
	],
	[
		'rozbalene',
		{
			label: $t.tabs.eshop,
			icon: 'local_mall',
			getData: getTabFromECommerce,
			moreLink: moreOffersTabLink,
		},
	],
] as const);

type GetTabOptions = {
	headers?: HeadersInit;
	locality?: NearbyLocality;
	radius?: number;
	category?: CategoryOption;
	selectedWatchdogId?: number | null;
	skipHistory?: boolean;
};

export async function loadTabOffers(
	currentTab: TabId,
	options?: GetTabOptions,
): Promise<{ offers: GetOffersResponse; categories?: CategoryData[] }> {
	const response = await tabsMap.get(currentTab)?.getData(options);

	if (!response) {
		return { offers: DEFAULT_OFFERS_VALUE };
	}

	return response;
}

export type TabId = typeof tabsMap extends Map<infer K, any> ? K : never;

// get tab ids from tabsMap
export const tabIds: TabId[] = Array.from(tabsMap.keys());

export async function tabFilterFromUrl(
	url: URL,
	ssrAllCategories: CategoryData[],
	headers?: HeadersInit,
): Promise<{
	tab: TabId;
	locality?: NearbyLocality;
	radius?: number;
	category?: CategoryOption;
	showSavedOffers: boolean;
}> {
	const tab = (url.searchParams.get('zalozka') || 'popularni') as TabId;
	const kde = url.searchParams.get('kde');
	const radius = url.searchParams.get('radius');
	const categoryId = url.searchParams.get('categoryId');
	const showSavedOffers = url.searchParams.get('showSavedOffers');

	let locality: NearbyLocality | undefined = undefined;
	let category: CategoryOption | undefined = undefined;

	if (kde) {
		const entityId = Number(kde.substring(1)) || 0;
		const entityType =
			Object.values(LOCALITY_TYPES).find((type) => type.startsWith(kde[0] ?? '')) || LOCALITY_TYPES.ADDRESS;

		if (entityId === 0) {
			locality = defaultLocality;
		} else {
			const resolvedLocality = await resolveLocality(
				{
					entity_id: entityId,
					entity_type: entityType || '',
				},
				headers,
			);

			locality = {
				...structuredClone(resolvedLocality),
				id: entityId,
				name: getLocalityName(resolvedLocality),
			};
		}
	}

	if (categoryId) {
		const catId = Number(categoryId);
		const { id, name, seoName } = ssrAllCategories.find((category) => category.id === catId) ?? defaultCategory;

		category = {
			id: id,
			name: name,
			seoName: seoName,
		};
	}

	return {
		tab,
		locality,
		radius: radius ? Number(radius) : undefined,
		category,
		showSavedOffers: !!showSavedOffers,
	};
}
