import noop from 'lodash/noop';
import { cn } from 'utils/cn';
import { Icon, type IconType } from 'components/atoms/Icon.tsx';
import type { Ref } from 'preact';

interface Props {
	ref?: Ref<HTMLButtonElement>;
	label: string;
	icon: IconType;
	isActive?: boolean;
	notification?: string;
	dataTest?: string;
	onClick?: () => void;
}

export function Tab({ ref, label, icon, onClick = noop, isActive, notification, dataTest }: Props) {
	return (
		<button
			ref={ref}
			class={cn(
				'flex flex-auto flex-col md:flex-row gap-2 justify-center items-center py-3 md:py-4 px-0 md:px-8 relative cursor-pointer text-dark-blue-50 hover:text-dark-blue hover:bg-neutral-3 rounded-t-2xl',
				isActive && 'text-dark-blue',
			)}
			onClick={onClick}
			data-test={dataTest}
		>
			<Icon type={icon} fill={isActive} size={24} className={cn({ 'animate-iconToFill': isActive })} />
			<span class="flex items-center gap-2">
				<span
					class={cn(
						isActive && 'text-dark-blue [text-shadow:_0_0_.65px_currentColor_,_0_0_.65px_currentColor]',
					)}
				>
					{label}
				</span>
				{notification && (
					<span class="px-1.5 bg-red text-white rounded-3xl text-1.5xs leading-5 font-bold text-shadow-none">
						{notification}
					</span>
				)}
			</span>
		</button>
	);
}
