import type { LocalityData } from 'service/locality/model.ts';
import type { CategoryOption } from 'components/homepage/signals.ts';
import type { HomepageTab } from 'lib/dot/enum/homepageTab.ts';
import { SearchType } from 'lib/dot/enum/searchType.ts';
import { getLocalityName, getLocalitySeoName } from 'utils/getLocalityName.ts';
import { LOCALITY_CELA_CR } from 'utils/constants.ts';
import { sendTabSearchAnalytics } from 'lib/dot/search/utils.ts';
import { localityTypeToAnalyticsLocalityType, sendFilterAnalytics } from 'lib/dot/filter/utils.ts';
import { DOTHelper } from 'lib/dot/DOTHelper.ts';
import { getKdeParameter } from 'utils/locality';

export function sendTabAnalytics({
	locality,
	category,
	radius,
	tab,
	usedGoods,
	itemCount,
	searchType,
}: {
	locality?: LocalityData;
	category: CategoryOption;
	radius?: number;
	tab: HomepageTab;
	usedGoods: boolean;
	itemCount: number;
	searchType: SearchType;
}) {
	locality = locality?.entityId === LOCALITY_CELA_CR.id ? undefined : locality;

	const kde = locality ? getKdeParameter(locality) : undefined;

	sendTabSearchAnalytics({
		searchType: searchType,
		filters: {
			'with-deal': 1,
			categoryName: category?.seoName,
			usedGoods: DOTHelper.sanitizeBoolean(usedGoods),
			...(kde && { kde }),
			...(locality && { locality: getLocalitySeoName(locality) }),
			...(radius && { radius: radius }),
		},
		itemCount: itemCount,
		tab: tab,
		usedGoods,
		categoryId: category?.id ?? 0,
		resolvedLocality: locality,
	});
}

/**
 * Utility wrapper to reduce params and copy-paste
 */
export function sendTabLocationAnalytics(locality: LocalityData | null, nearby: boolean, tab: HomepageTab) {
	sendFilterAnalytics({
		filterName: 'locality',
		filterType: 'value',
		actionType: locality ? 'select' : 'deselect',
		filterValue: locality ? getLocalityName(locality) : '',
		localityType: locality ? localityTypeToAnalyticsLocalityType(locality.entityType) : '',
		nearby: DOTHelper.sanitizeBoolean(nearby),
		...(tab && { tab }),
	});
}

export function sendTabRadiusAnalytics(radius: number, tab: HomepageTab) {
	sendFilterAnalytics({
		filterName: 'locality',
		filterType: 'radius',
		actionType: 'select',
		filterValue: radius.toString(),
		...(tab && { tab }),
	});
}
