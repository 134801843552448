import { cn } from 'utils/cn';
import {
	defaultCategory,
	getTabFromECommerce,
	type TabId,
	tabActiveCategory,
	tabOffers,
	tabDisableUseLayoutEffect,
} from './signals';
import { CategoryDropdown } from './CategoryDropdown';
import { useSignal } from '@preact/signals';
import type { CategoryData } from 'service/category/model';
import { useLayoutEffect, useMemo } from 'preact/hooks';
import { BarDescription } from 'components/homepage/BarDescription.tsx';
import { localize } from 'i18n/localize.ts';
import { SearchType } from 'lib/dot/enum/searchType.ts';
import { sendTabAnalytics } from 'components/homepage/utils.ts';
import { HomepageTab } from 'lib/dot/enum/homepageTab.ts';
import { CATEGORY_ID } from 'utils/category';

interface Props {
	ssrActiveTab?: TabId;
	ssrAllCategories: CategoryData[];
	className?: string;
}

const $t = localize();

const PROHIBITED_CATEGORY_IDS = [CATEGORY_ID.SLUZBY, CATEGORY_ID.NEMOVITOSTI];

export function ECommerceBar({ ssrActiveTab, ssrAllCategories, className }: Props) {
	const serverLoad = useSignal(ssrActiveTab === 'rozbalene');
	const categoryId = (tabActiveCategory.value || defaultCategory).id;
	const eCommerceCategories = useMemo(
		() => ssrAllCategories.filter((category) => !PROHIBITED_CATEGORY_IDS.includes(category.id)),
		[],
	);

	useLayoutEffect(() => {
		if (!className?.includes('hidden') && !tabDisableUseLayoutEffect.peek()) {
			const { id, name, seoName } =
				eCommerceCategories.find((category) => category.id === categoryId) ?? defaultCategory;

			if (!serverLoad.peek()) getTabFromECommerce({ category: { id, name, seoName } });
			else serverLoad.value = false;
		}
	}, [className]);

	return (
		<div class={cn('flex flex-wrap items-center justify-between gap-4 smp:flex-nowrap', className)}>
			<BarDescription text={$t.tabsDescriptions.eshop} />
			<CategoryDropdown
				dataTest="tab-category-filter"
				categories={eCommerceCategories}
				selectedCategoryId={categoryId}
				placement={'bottom-right'}
				showCount={false}
				onCategorySelect={async (category) => {
					if (category.id === categoryId) return;

					tabActiveCategory.value = category;

					await getTabFromECommerce({ category });
					sendTabAnalytics({
						category: category,
						tab: HomepageTab.USED,
						usedGoods: true,
						itemCount: tabOffers.peek().pagination.total,
						searchType: SearchType.CATEGORY,
					});
				}}
				className="md:!h-[300px]"
			/>
		</div>
	);
}
