import { useSignal } from '@preact/signals';
import { useLayoutEffect } from 'preact/hooks';
import {
	getTabBookmarkedOffers,
	getTabWatchdogs,
	tabDisableUseLayoutEffect,
	type TabId,
} from 'components/homepage/signals';
import { useSignals } from 'context/SignalsContext';
import { localize, pluralize } from 'i18n/localize';
import { cn } from 'utils/cn';
import { WatchdogItem } from 'components/watchdog/WatchdogItem.tsx';
import { user } from 'signals/login.ts';
import { EmptyStateOverlay } from 'components/atoms/EmptyStateOverlay.tsx';

const $t = localize();

interface Props {
	ssrActiveTab?: TabId;
	className?: string;
	newOffers?: number;
}

const SKELETON_COUNT = 9;

function computeSkeletons(count: number, animate?: boolean) {
	const newOfferListJsx = Array(count)
		.fill(0)
		.map((_, index) => (
			<li
				key={`skeleton-${index}`}
				class={cn('h-[76.81px] bg-light-gray rounded-xl', animate && 'animate-pulse')}
			></li>
		));

	return newOfferListJsx;
}

export function SavedOfferBar({ ssrActiveTab, className, newOffers }: Props) {
	const { tabShowSavedOffers } = useSignals(['tabShowSavedOffers']);
	const serverLoad = useSignal(ssrActiveTab === 'ulozene');
	const { offers, watchdogs } = useSignals(['offers', 'watchdogs']);

	useLayoutEffect(() => {
		if (!serverLoad.peek()) {
			if (!className?.includes('hidden') && !tabDisableUseLayoutEffect.peek()) {
				if (tabShowSavedOffers.peek()) {
					getTabBookmarkedOffers();
				} else {
					getTabWatchdogs();
				}
			}
		} else {
			serverLoad.value = false;
		}
	}, [className, tabShowSavedOffers.value]);

	const isLogoutUser = user.value.state !== 'login';
	const showIsLogoutMessage = !watchdogs.value.isLoading && isLogoutUser;
	const showEmptyOverlay = !watchdogs.value.isLoading && !watchdogs.value.data.length;

	return (
		<div class={className}>
			<div class={cn('flex justify-center mt-6')}>
				<div class="flex border border-solid border-gray rounded-xl text-sm md:text-base text-dark-blue-70">
					<button
						class={cn(
							'w-[200px] py-2 m-0.5 rounded-lg inline-flex gap-1 items-center justify-center',
							!tabShowSavedOffers.value && 'bg-dark-blue-10 font-bold text-dark-blue',
						)}
						onClick={() => {
							tabShowSavedOffers.value = false;
						}}
					>
						{$t.tabBookmarked.watchdogs}
						{newOffers && <span class="px-1.5 bg-red text-white rounded-3xl text-1.5xs">{newOffers}</span>}
					</button>
					<button
						class={cn(
							'w-[200px] py-2 m-0.5 rounded-lg inline-flex gap-1 items-center justify-center',
							tabShowSavedOffers.value && 'bg-dark-blue-10 font-bold text-dark-blue',
						)}
						onClick={() => {
							tabShowSavedOffers.value = true;
						}}
					>
						{$t.tabBookmarked.favorite}
					</button>
				</div>
			</div>
			{!tabShowSavedOffers.value ? (
				// If we are viewing saved watchdogs
				watchdogs.value.isLoading ? (
					<div class="mt-5">
						<div class="w-36 h-6 bg-light-gray rounded animate-pulse" />
						<ul class="relative flex flex-col gap-2 mt-4 md:min-h-[812px]">
							{computeSkeletons(SKELETON_COUNT, true)}
						</ul>
					</div>
				) : (
					<div class="mt-5">
						<div class="h-6 text-sm leading-6 text-dark-blue-50">
							{pluralize(watchdogs.value.data.length, $t.watchdog.watchdogsLabel, true)}
						</div>
						<ul
							class={cn(
								'relative flex flex-col gap-2 mt-4',
								showEmptyOverlay && 'md:min-h-72',
								watchdogs.value.data.length === 0 && '[&>li]:hidden sm:[&>li]:block grid-cols-1',
							)}
						>
							{showEmptyOverlay && (
								<EmptyStateOverlay
									className="md:shadow-none md:transform-none md:inline-block md:inset-auto md:mx-auto md:static"
									title={
										isLogoutUser
											? $t.offerList.emptyStateTitleSavedSearchLogout
											: $t.offerList.emptyStateTitleSavedSearch
									}
									subtitle={$t.offerList.emptyStateSubtitleSavedSearch}
									showLoginButton={showIsLogoutMessage}
									loginOptions={{ sourceComponent: 'saved_search_add' }}
								/>
							)}
							{watchdogs.value.data.map((dog) => (
								<li key={dog.id}>
									<WatchdogItem watchdog={dog} />
								</li>
							))}
						</ul>
					</div>
				)
			) : (
				// If we are viewing saved offers
				<div class="mt-5">
					{offers.value.isLoading ? (
						<div class="w-36 h-6 bg-light-gray rounded animate-pulse" />
					) : (
						<div class="h-6 text-sm leading-6 text-dark-blue-50">
							{pluralize(offers.value.response.pagination?.total, $t.favorite.title_plr, true)}
						</div>
					)}
				</div>
			)}
		</div>
	);
}
