import { useSignal } from '@preact/signals';
import { Tab } from 'components/atoms/Tab.tsx';
import { tabIds, tabsMap, type TabId } from 'components/homepage/signals.ts';
import { useEffect, useRef } from 'preact/hooks';
import { cn } from 'utils/cn';
import type { WatchdogsSummary } from 'service/watchdog/model.ts';

const defaultPosition = { width: '201', left: '23' };

type Props = {
	currentTab: TabId;
	onTabChange: (tab: TabId) => void;
	watchdogsSummary?: WatchdogsSummary;
};

export function Tabs({ currentTab, onTabChange, watchdogsSummary }: Props) {
	const tabsRef = useRef<((HTMLButtonElement & { base?: HTMLButtonElement }) | null)[]>([]);
	const tabUnderline = useSignal(defaultPosition);

	useEffect(() => {
		const setTabPosition = () => {
			const activeTabIndex = tabIds.indexOf(currentTab);
			const tabElement = tabsRef.current[activeTabIndex]?.base;

			if (tabElement) {
				tabUnderline.value = {
					width: `${tabElement.clientWidth ?? 0}px`,
					left: `${tabElement.offsetLeft ?? 0}px`,
				};
			} else {
				tabUnderline.value = defaultPosition;
			}
		};

		setTabPosition();
		globalThis.window.addEventListener('resize', setTabPosition);
		return () => globalThis.window.removeEventListener('resize', setTabPosition);
	}, [currentTab]);

	const notificationText =
		watchdogsSummary && watchdogsSummary.newOffersCount > 0
			? watchdogsSummary.newOffersCount.toString()
			: undefined;

	return (
		<div class="relative w-full">
			<div class="flex w-full gap-1 md:gap-4">
				{tabIds.map((tabId, idx) => {
					const tab = tabsMap.get(tabId);

					if (!tab) {
						return null;
					}

					return (
						<Tab
							ref={(ref) => (tabsRef.current[idx] = ref)}
							isActive={currentTab === tabId}
							label={tab.label}
							icon={tab.icon}
							onClick={() => onTabChange(tabId)}
							notification={tabId === 'ulozene' ? notificationText : undefined}
							dataTest={`tab-${tabId}`}
						/>
					);
				})}
			</div>

			<div
				class={cn('absolute bottom-0 block h-0.5 bg-neutral-black transition-all duration-300')}
				style={tabUnderline.value || defaultPosition}
			/>
		</div>
	);
}
